import { AutoatendimentoLoginResponse, AutoatendimentoProduto } from '@/models/autoatendimentoLogin.response';
import { AutoCartModel } from '@/models/autoCartModel';
import { AutoClientData } from '@/models/autoClientData.model';
import { AutoColorsModel } from '@/models/autoColorsModel';
import { ScanConteudo } from '@/models/autoScan';
import { AutoScanSucessError } from '@/models/autoScanSuccessError';
import { MarketCartItemListModel, MarketLoginDataModel, PdvItemListModel } from '@/models/hotelModels';
import { Language } from '@mui/icons-material';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	// reducers de controle das páginas
	selfServiceType: 'atendimento' as 'atendimento' | 'comandas' | 'mercado',
	selectedDepartment: 0,
	iddlePage: true,
	cartPage: false,
	paymentPage: false,
	productDetailsPage: false,
	isLoggedIn: false,

	//reducer de controle de funcionalidades
	printCupon: false,
	modalScan: false,
	modalDigitBarCode: false,

	//reducers containers para informações importantes
	colors: {} as AutoColorsModel,
	loginData: {} as AutoatendimentoLoginResponse,

	// reducers de informações do cliente
	activeClientPage: null as 'takeHome' | 'name' | 'cpf' | 'email' | 'phone' | 'outros' | null,
	clientActiveClientPages: [] as string[],
	clientData: { takeHome: '', name: '', email: '', cpf: '', phone: '', outros: '' } as AutoClientData,

	// reducers interface autopagamento
	activeProduct: {} as AutoatendimentoProduto,

	// reducers modal SuccessError
	autoModalSuccessError: {} as AutoScanSucessError,

	//Reducers de informações TEF
	autoText: '',
	autoTitle: '',
	errorText: '',
	qrCode: '',

	// reducers de controle do modal principal do app
	modalText: '',
	modalType: '' as 'success' | 'error' | 'ok' | 'YN' | 'question' | 'number' | 'text',
	modalOpen: false,

	// reducers de carrinhos
	cart: [] as AutoCartModel[],
	scannedCarts: [] as ScanConteudo[],
	tempCartItem: {} as AutoCartModel,

	// holders de informações temporárias e importantes
	cardType: '',
	cupomString: '',
	cupomDiscount: 0,
	cupomTotalDiscount: 0,
	oldCancelTransactions: 0,
	transactionCode: 0,
	shouldCancel: false,
	shouldReturn: false,
	transactionType: 'None' as 'Sale' | 'Configure' | 'Cancel' | 'Return' | 'None' | 'Start',
	canceledAfterModal: false,
	zeroMessages: [] as any[],

	//reducersMakert
	autoMarketCompatibleProducts: [] as (PdvItemListModel & { zeroProduct: AutoatendimentoProduto })[],
	autoMarketCart: [] as MarketCartItemListModel[],
	autoMarketProductList: [] as PdvItemListModel[],
	autoMarketLoginInfo: {} as MarketLoginDataModel,
	hotelPayment: false,

	// linguagem
	language: 'PT' as 'PT' | 'EN' | 'ES',
};

const eventsSlice = createSlice({
	name: 'pos',
	initialState,
	reducers: {
		changeZeroMessages: (state, action) => {
			state.zeroMessages = action.payload;
		},
		changeAutoMarketCompatibleProducts: (state, action) => {
			state.autoMarketCompatibleProducts = action.payload;
		},
		changeAutoMarketLoginInfo: (state, action) => {
			state.autoMarketLoginInfo = action.payload;
		},
		changeAutoMarketProductList: (state, action) => {
			state.autoMarketProductList = action.payload;
		},
		changeAutoMarketCart: (state, action) => {
			state.autoMarketCart = action.payload;
		},
		changeAutoHotelPayment: (state, action) => {
			state.hotelPayment = action.payload;
		},
		changeAutoActiveClientPage: (state, action) => {
			state.activeClientPage = action.payload;
		},
		changeCanceledAfterModal: (state, action) => {
			state.canceledAfterModal = action.payload;
		},
		changeAutoPrintCupon: (state, action) => {
			state.printCupon = action.payload;
		},
		changeAutoSelectedDepartment: (state, action) => {
			state.selectedDepartment = action.payload;
		},
		changeAutoCardType: (state, action) => {
			state.cardType = action.payload;
		},
		changeAutoActiveProduct: (state, action) => {
			state.activeProduct = action.payload;
		},
		changeAutoOldCancelTransactions: (state, action) => {
			state.oldCancelTransactions = action.payload;
		},
		changeAutoCart: (state, action) => {
			state.cart = action.payload;
		},
		changeAutoCartPage: (state, action) => {
			state.cartPage = action.payload;
		},
		changeAutoClientActiveClientPages: (state, action) => {
			state.clientActiveClientPages = action.payload;
		},
		changeAutoClientData: (state, action) => {
			state.clientData = action.payload;
		},
		changeAutoColors: (state, action) => {
			state.colors = action.payload;
		},
		changeAutoErrorText: (state, action) => {
			state.errorText = action.payload;
		},
		changeAutoIddlePage: (state, action) => {
			state.iddlePage = action.payload;
		},
		changeAutoLoginData: (state, action) => {
			state.loginData = action.payload;
		},
		changeAutoLoggedIn: (state, action) => {
			state.isLoggedIn = action.payload;
		},
		changeAutoModalDigitBarCode: (state, action) => {
			state.modalDigitBarCode = action.payload;
		},
		changeAutoModalScan: (state, action) => {
			state.modalScan = action.payload;
		},
		changeAutoModalOpen: (state, action) => {
			state.modalOpen = action.payload;
		},
		changeAutoModalText: (state, action) => {
			state.modalText = action.payload;
		},
		changeAutoModalType: (state, action) => {
			state.modalType = action.payload;
		},
		changeAutoSelfServiceType: (state, action) => {
			state.selfServiceType = action.payload;
		},
		changeAutoPaymentPage: (state, action) => {
			state.paymentPage = action.payload;
		},
		changeAutoQrCode: (state, action) => {
			state.qrCode = action.payload;
		},
		changeAutoScannedCarts: (state, action) => {
			state.scannedCarts = action.payload;
		},
		changeAutoShouldCancel: (state, action) => {
			state.shouldCancel = action.payload;
		},
		changeAutoShouldReturn: (state, action) => {
			state.shouldReturn = action.payload;
		},
		changeAutoSuccesError: (state, action) => {
			state.autoModalSuccessError = action.payload;
		},
		changeAutoProductDetailsPage: (state, action) => {
			state.productDetailsPage = action.payload;
		},
		changeAutoTempCartItem: (state, action) => {
			state.tempCartItem = action.payload;
		},
		changeAutoText: (state, action) => {
			state.autoText = action.payload;
		},
		changeAutoTitle: (state, action) => {
			state.autoTitle = action.payload;
		},
		changeAutoTransactionCode: (state, action) => {
			state.transactionCode = action.payload;
		},
		changeAutoTransactionType: (state, action) => {
			state.transactionType = action.payload;
		},
		reset: () => initialState,
		resetAutoClientData: state => {
			state.clientData = { takeHome: 'false', name: '', email: '', cpf: '', phone: '', outros: '' } as AutoClientData;
		},
		resetAutoTempCartItem: state => {
			state.tempCartItem = initialState.tempCartItem;
		},
		changeAutoCupomDiscount: (state, action) => {
			state.cupomDiscount = action.payload;
		},
		changeAutoCupomString: (state, action) => {
			state.cupomString = action.payload;
		},
		changeAutoCupomTotalDiscount: (state, action) => {
			state.cupomTotalDiscount = action.payload;
		},
		resetAutoatendimento: state => {
			state.cart = [];
			state.activeProduct = {} as AutoatendimentoProduto;
			state.tempCartItem = {} as AutoCartModel;
			state.cartPage = false;
		},
		removeScannedCart: (state, action) => {
			state.scannedCarts = state.scannedCarts.filter(cart => cart.comanda !== action.payload);
		},
		changeAutoLanguage: (state, action) => {
			state.language = action.payload;
		},
	},
});

export const {
	removeScannedCart,
	changeAutoActiveClientPage,
	changeAutoSelectedDepartment,
	changeAutoProductDetailsPage,
	changeAutoActiveProduct,
	changeAutoCart,
	changeAutoCartPage,
	changeAutoCupomString,
	changeAutoPrintCupon,
	changeAutoOldCancelTransactions,
	changeAutoCupomDiscount,
	changeAutoClientActiveClientPages,
	changeAutoCupomTotalDiscount,
	changeAutoClientData,
	changeAutoColors,
	changeAutoCardType,
	changeAutoErrorText,
	changeAutoIddlePage,
	changeZeroMessages,
	changeAutoLoginData,
	changeAutoModalDigitBarCode,
	changeAutoModalScan,
	changeAutoMarketCompatibleProducts,
	changeAutoModalText,
	changeAutoModalType,
	changeAutoModalOpen,
	changeAutoSelfServiceType,
	changeAutoPaymentPage,
	changeAutoQrCode,
	changeAutoScannedCarts,
	changeAutoShouldCancel,
	changeAutoShouldReturn,
	changeAutoHotelPayment,
	changeAutoSuccesError,
	changeAutoMarketCart,
	changeAutoMarketLoginInfo,
	changeAutoMarketProductList,
	changeAutoLoggedIn,
	changeAutoTempCartItem,
	changeAutoText,
	changeAutoTitle,
	changeAutoTransactionCode,
	changeAutoTransactionType,
	reset,
	resetAutoClientData,
	resetAutoTempCartItem,
	resetAutoatendimento,
	changeAutoLanguage,
	changeCanceledAfterModal,
} = eventsSlice.actions;
export default eventsSlice.reducer;
